
export default {

	props: {
		offers: Array,

		sliderId: {
			type: String,
			default: ''
		}
	},
	mounted() {


		// const sd = new swiper.default('.swiper.swiper--catalog.swiper--' + this.sliderId, {
		// 	modules: [swiper.Navigation, swiper.Autoplay],
		// 	loop: false,
		// 	// autoplayDisableOnInteraction: true,
		// 	autoplay: false,
		// 	initialSlide: 0,
		// 	// centeredSlides: true,
		// 	// activeIndex: 5,
		// 	watchSlidesProgress: true,
		// 	// allowTouchMove: false,
		// 	// centeredSlidesBounds:true,
		// 	// roundLengths: true,

		// 	// centerInsufficientSlides:true,
		// 	// slidesOffsetAfter: this.$refs.swiperСatalog.getBoundingClientRect().left,
		// 	// cssMode:true,
		// 	// edgeSwipeDetection:'prevent',
		// 	// enabled: false,
		// 	slidesPerView: 1.25,
		// 	spaceBetween: 20,
		// 	// slidesOffsetBefore: leftPadding,
		// 	// slidesOffsetAfter: leftPadding,
		// 	breakpoints: {
		// 		// when window width is >= 320px
		// 		0: {
		// 			// slidesOffsetBefore: 16,
		// 		},
		// 		1360: {
		// 			slidesPerView: 4,
		// 			spaceBetween: 24,
		// 		},
		// 		800: {
		// 			slidesPerView: 3,
		// 			spaceBetween: 24,
		// 		},
		// 		600: {
		// 			slidesPerView: 2,
		// 			spaceBetween: 20,
		// 		},

		// 	},
		// 	navigation: {
		// 		nextEl: '.catalog__list .swiper-button-next',
		// 		prevEl: '.catalog__list .swiper-button-prev',
		// 	},
		// })

		// sd.on('touchStart', (swiper, event) => {
		// 	if (event.target.nodeName === 'IMG') swiper.allowTouchMove = false

		// })

		// sd.on('touchEnd', (swiper, event) => {
		// 	swiper.allowTouchMove = true
		// })




	},
	methods: {
		tabChange(car) {
			this.set = car.slug
			this.$emit('tabChange', car)
		}
	}
}
